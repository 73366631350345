<template>

  <header class="haeder">
    <v-app-bar
        color="surface-variant"
        dark
    >
      <v-btn @click="goToIndex">
        <v-toolbar-title>PLS-II Alarm Service</v-toolbar-title>
      </v-btn>

      <v-spacer></v-spacer>
      <v-btn id="btn_ring"  class="mr-1" :icon="iconName">

      </v-btn>
      <v-btn v-if="$global.login" id="btn_setting" :icon=mdiCogOutline class="mr-1" @click="goToSetting"></v-btn>
      <v-btn v-if="!$global.login" id="btn_login" :icon="mdiLogin" class="mr-1" @click="goToLogin"></v-btn>
      <v-btn v-if="$global.login" id="btn_logout" :icon="mdiLogout" class="mr-1" @click="goToLogout"></v-btn>
    </v-app-bar>
  </header>
</template>

<script setup>
import { onMounted } from "vue";
import { requestPermission} from "@/firebase-sdk";
import { mdiLogin, mdiLogout, mdiCogOutline, mdiBell, mdiBellSleep, mdiBellOff } from '@mdi/js'

onMounted(()=> {
  const btn = document.querySelector("#btn_ring");
  btn.addEventListener('click', requestPermission);
});

</script>

<script>
export default {

  data() {
    return {
    };
  },
  methods: {
    goToLogin() {
      this.$global.login = false;
      this.$router.replace(`/login` );
    },
    goToLogout() {
      const data = localStorage.getItem("userId");
      this.$http.post("https://" + process.env.VUE_APP_BASE_URL + ":45000/api/logout", data, {
        headers:{
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        }
      })
      .then(response => {
        console.log(response.data['message']);
        if(response.data['message'] === "USER_LOGOUT"){
          this.$global.login = false;
          localStorage.removeItem('access_token');
          localStorage.removeItem('userId');
          window.location.reload();
        }
      });


      this.$router.replace(`/` );
    },
    goToSetting(){
      this.$router.replace(`/setting`);
    },
    goToIndex(){
      this.$router.replace(`/` );
    },
    authentication(){
      const data = {
        userId: localStorage.getItem("userId"),
      };

      this.$http.post("https://"+process.env.VUE_APP_BASE_URL+":45000/api/auth", data, {
        headers:{
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        }
      })
          .then(response => {
            if(response.data['message'] === "ACCESS_TOKEN_OK"){
              this.$global.login = true;
              localStorage.setItem('access_token', response.data['accessToken']);
            } else if(response.data['message'] === "ACCESS_TOKEN_REFRESHED"){
              this.$global.login = true;
              localStorage.setItem('access_token', response.data['accessToken']);
              //window.location.reload();
            } else if(response.data['message'] === "USER_EXPIRED_REFRESH_TOKEN"){
              this.$global.login = false;
              this.goToLogin();
            } else if(response.data['message'] === "USER_TOKEN_NOT_FOUND"){
              this.$global.login = false;
              //this.goToLogin();
            } else if(response.data['message'] === "INVALID_ACCESS_TOKEN"){
              this.$global.login = false;
              //this.goToLogin();
            } else if(response.data['message'] === "INVALID_TOKEN"){
              this.$global.login = false;
              //this.goToLogin();
            }
          })
          .catch(() => {
            this.$global.login = false;
            this.goToLogin();
          })
    },
    newRender(){
      this.$forceUpdate();
    }
  },
  mounted() {
    const push_enabled = localStorage.getItem("push_enabled");
    if(push_enabled === undefined || push_enabled === null){
      localStorage.setItem("push_enabled", "false");
    }

    if(Notification.permission === "denied"){
        localStorage.setItem("push_enabled","denied")
    }

    const vm = this;
    vm.authentication();
    /*
    vm.auth = setInterval(() => {
      vm.authentication();
    }, 1000*60*1);*/
  },
  computed:{
    iconName(){
      const result = localStorage.getItem("push_enabled");
      if(result === "true"){
        return mdiBell;
      } else if (result === "false"){
        return mdiBellSleep;
      } else if (result === "denied"){
        return mdiBellOff;
      }
      return mdiBellSleep;
    }
  }
};
</script>


<style scoped>
v-toolbar-title{
  font-size: clamp(14px, 2vw, 18px);
}

@media (max-width:600px){
  v-btn{
    font-size: clamp(14px, 2vw, 18px);
  }
}

@media (min-width:1024px){
  v-btn{
    font-size: clamp(18px, 2vw, 24px);
  }
}
</style>