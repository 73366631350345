import {createRouter, createWebHistory} from 'vue-router'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('./components/mIndex.vue'),
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('./components/mLogin.vue'),
    },
    {
        path: '/register',
        name: 'Register',
        component: () => import('./components/mRegister.vue'),
    },
    {
        path: '/verify/:userId',
        name: 'Verify',
        component: () => import('./components/mVerify.vue'),
    },
    {
        path: '/setting',
        name: 'Setting',
        component: () => import('./components/mSetting.vue'),
    },
    {
        path: '/addPv',
        name: 'AddPv',
        component: () => import('./components/mAddPv.vue'),
    },
    {
        path: '/managePv',
        name: 'ManagePv',
        component: () => import('./components/mManagePv.vue'),
    },
    {
        path: '/modifyPv/:pvName',
        name: 'ModifyPv',
        component: () => import('./components/mModifyPvConfig.vue'),
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});




export default router;