import {initializeApp} from "firebase/app";
import {getMessaging, getToken, onMessage } from "firebase/messaging";
import axios from "axios";

const firebaseConfig = {
    apiKey: "AIzaSyCEK8vLfNePewetr0MrQBFo9gztfSodNlw",
    authDomain: "pls2alarm.firebaseapp.com",
    projectId: "pls2alarm",
    storageBucket: "pls2alarm.appspot.com",
    messagingSenderId: "1089872813324",
    appId: "1:1089872813324:web:e9a9651e8e378bd7a8fd29",
    measurementId: "G-H0XMK85E5H"
};

const firebaseApp= initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

if('serviceWorker' in navigator) {
    //navigator.serviceWorker.getRegistrations().then((registrations) => {
        //if(registrations.length === 0) {
            navigator.serviceWorker.register('/firebase-messaging-sw.js' )
                .then(function (registration) {
                    console.log('Service Worker registered with scope:', registration.scope);
                }).catch(function (err) {
                console.error('Service Worker registration failed:', err);
            });
        //}
    //});
}


onMessage(messaging, (payload) => {
    //console.log('Message received: ' , payload);

    if(Notification.permission === 'granted') {
        const osInfo = getOSInfo();

        if(osInfo === "Android"){
            const notificationTitle = payload.data.title;
            const notificationOptions = {
                body: payload.data.body,
                icon: '/icons/icon_120x120.png'
            };
            navigator.serviceWorker.ready.then((registration) => {
                registration.showNotification(notificationTitle, notificationOptions);
            })
        }else if(osInfo === "iOS" || osInfo === "MacOS"){
            const notificationTitle = payload.notification.title;
            const notificationOptions = {
                body: payload.notification.body,
                icon: '/icons/icon_120x120.png'
            };
            navigator.serviceWorker.ready.then((registration) => {
                registration.showNotification(notificationTitle, notificationOptions);
            })

        } else{
            const notificationTitle = payload.data.title;
            const notificationOptions = {
                body: payload.data.body,
                icon: '/icons/icon_120x120.png'
            };
            new Notification(notificationTitle, notificationOptions);
        }
    }
})

export function requestPermission() {
    void Notification.requestPermission().then((permission) => {
        if(localStorage.getItem('push_enabled')==="true" && permission === "granted"){
            if(confirm('알람 기능을 중단하시겠습니까?')){
                const data = {
                    userId: localStorage.getItem("userId"),
                    token: localStorage.getItem("KNETO"),
                    uuid : localStorage.getItem("browserUUID")
                };
                const _axios = axios.create();
                _axios.post("https://"+process.env.VUE_APP_BASE_URL+":45000/api/removeToken", data, {
                    headers:{
                        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    }
                })
                .then(response => {
                    console.log(response.data);
                    localStorage.setItem("push_enabled", "false");
                    localStorage.removeItem("KNETO");
                    window.location.reload();
                });
            }
        } else if (localStorage.getItem('push_enabled')==="false" && permission === "granted"){
            if(confirm('알람 기능을 사용하시겠습니까?')){
                requestGrantedNotification();
            }
        } else if (localStorage.getItem('push_enabled')==="denied" && permission === "denied"){
            localStorage.setItem("push_enabled", "denied");
            alert('브라우저에 의해 차단된 상태입니다. 해제가 필요합니다.');
        } else if(localStorage.getItem('push_enabled')==="denied" && permission === "granted"){
            if(confirm('알람 기능을 사용하시겠습니까?2')){
                requestGrantedNotification();
            }
        }


/*
        if (permission === "granted") {
            requestGrantedNotification();
        } else if(permission === 'denied'){
            localStorage.setItem("push_enabled", "denied");
        }*/
    });
}

function requestGrantedNotification(){
    getToken(messaging, { vapidKey: 'BPm2g2Ma5KNt-izEuWusiE_-F-IQdjBpQskZe0MyHmJCX5U2UJC3waa0v7N8EKVc7WRzvlpDUX-FYotCJvmEV_o'}).then((token) => {
        const data = {
            userId: localStorage.getItem("userId"),
            token: token,
            uuid : getBrowserUUID(),
            OS: getOSInfo(),
            host: window.location.hostname
        };
        const _axios = axios.create();
        _axios.post("https://"+process.env.VUE_APP_BASE_URL+":45000/api/token", data, {
            headers:{
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            }
        })
            .then(response => {
                console.log(response.data);
                localStorage.setItem("push_enabled", "true");
                localStorage.setItem("KNETO", token);
                window.location.reload();
            });

    }).catch((error) => {
        console.error(error);
    })
}

function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

function getBrowserUUID() {
    let uuid = localStorage.getItem('browserUUID');
    if (!uuid) {
        uuid = generateUUID();
        localStorage.setItem('browserUUID', uuid);
    }
    return uuid;
}

function getOSInfo() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }
    if (/win/i.test(userAgent)) {
        return "Windows";
    }
    if (/android/i.test(userAgent)) {
        return "Android";
    }
    if (/linux/i.test(userAgent)) {
        return "Linux";
    }
    if (/iphone|ipad|ipod/i.test(userAgent)) {
        return "iOS";
    }
    if (/mac/i.test(userAgent)) {
        return "MacOS";
    }

    return "Unknown OS";
}